import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  { path: '/', redirect:'test' },
  { path: '/test/:id/:authToken?', name: 'test', component: ( ) => import( '@/views/TestExecution.vue' ), props: true },
  { path: '/test/:id/watfocus/:authToken?', name: 'focustest', component: ( ) => import( '@/views/TestExecution.vue' ), props: true },
  { path: '/preview/:id', name: 'preview', component: ( ) => import( '@/views/TestPreview.vue' ), props: true },
  { path: '/preview/:id/watfocus', name: 'focuspreview', component: ( ) => import( '@/views/TestPreview.vue' ), props: true },
  { path: '/testingTest/:id', name: 'testingTest', component: ( ) => import( '~/components/Testing/testingTest.vue' ), props: true }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router;
