import { createI18n } from 'vue-i18n/index';
import { merge } from 'lodash';

import esMessagesGeographic from '~/i18n/geographic/es.json';
import esMessages from '~/i18n/execution/es.json';

import itMessagesGeographic from '~/i18n/geographic/it.json';
import itMessages from '~/i18n/execution/it.json';

import enMessagesGeographic from '~/i18n/geographic/en.json';
import enMessages from '~/i18n/execution/en.json';

import deMessagesGeographic from '~/i18n/geographic/de.json';
import deMessages from '~/i18n/execution/de.json';

/* import glMessagesGeographic from '#/i18n/geographic/de.json'; */
import glMessages from '~/i18n/execution/gl.json';
import euMessages from '~/i18n/execution/eu.json';
import caMessages from '~/i18n/execution/ca.json';
import ptMessages from '~/i18n/execution/pt.json';
import frMessages from '~/i18n/execution/fr.json';
import nlMessages from '~/i18n/execution/nl-BE.json';
import roMessages from '~/i18n/execution/ro.json';
import ruMessages from '~/i18n/execution/ru.json';
import valMessages from '~/i18n/execution/val.json';

const messages = {
  es: merge(esMessages, esMessagesGeographic),
  it: merge(itMessages, itMessagesGeographic),
  en: merge(enMessages, enMessagesGeographic),
  de: merge(deMessages, deMessagesGeographic),
  gl: glMessages,
  eu: euMessages,
  ca: caMessages,
  pt: ptMessages,
  fr: frMessages,
  nl: nlMessages,
    ro: roMessages,
    ru: ruMessages,
    val:valMessages
};

export default createI18n({
  locale: 'es',
  fallbackLocale: 'es',
  messages: messages
});
