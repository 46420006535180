import ApiService from '@/service'
import { VueCookieNext } from 'vue-cookie-next'

  const state = {
    authToken: "",
    testerVisitorToken: ""
  }

  const getters = {
    authToken(state: any){
      return state.authToken
    },
  }
  const mutations = {
    setStateAuthtoken(state: any,data: any){
      state.authToken = data;
    }
  }

  if(VueCookieNext.getCookie('authToken')){
    state.authToken = VueCookieNext.getCookie('authToken')
  }
  if(VueCookieNext.getCookie('testerVisitorToken')){
    state.testerVisitorToken = VueCookieNext.getCookie('testerVisitorToken')
  }

export default{
  state,
  getters,
  mutations
}
