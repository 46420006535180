import { createStore } from 'vuex'
import executionModule from '~/store/execution.store'
import watuxModule from '~/store/watux.store'
import lovModule from './lov.store'
import authModule from './auth.store'

export interface State {
  executionModule: any
  lovModule: any
  authModule: any
  watuxModule: any
}

export default createStore<State>({
  modules: {
    executionModule: executionModule,
    lovModule: lovModule,
    authModule: authModule,
    watuxModule: watuxModule
  },
});
